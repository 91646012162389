// import * as firebase from "firebase/app"; // old way, wont work anymore
import firebase from "firebase/app";
import "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAxyvZFJPfeymCmplUB_hj1IIYdQy0pglM",
    authDomain: "resonance-db933.firebaseapp.com",
    projectId: "resonance-db933",
    storageBucket: "resonance-db933.appspot.com",
    messagingSenderId: "387743966736",
    appId: "1:387743966736:web:453cd65529f6b8812f8c95",
    measurementId: "G-JLX224G40C"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

/* if (typeof window !== 'undefined' && !firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
    if ('measurmentId' in firebaseConfig) firebase.analytics();
}
 */
// firebase.analytics();

// export auth
export const auth = firebase.default.auth();
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();