import React, { useEffect, lazy, Suspense } from 'react';
import AOS from 'aos';
import { Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadingOutlined } from '@ant-design/icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'aos/dist/aos.css';

import { auth } from './firebase';
import { useDispatch } from 'react-redux';
import { currentUser } from './functions/auth';


const Home = lazy(() => import('./pages/Home'));
const WebDev = lazy(() => import('./pages/webDev'));
const Login = lazy(() => import('./pages/auth/Login'));
const Register = lazy(() => import('./pages/auth/Register'));
const RegisterComplete = lazy(() => import('./pages/auth/RegisterComplete'));

const TemplateHome = lazy(() => import('./pages/templates/TemplateHome'));
const Blog = lazy(() => import('./pages/blogPosts/Blog'));
const Blogpost = lazy(() => import('./components/blogpost/Blogpost'));
const Product = lazy(() => import('./pages/Product'));
const Payment = lazy(() => import("./pages/Payment"));

// components
const NavNav = lazy(() => import('./components/nav/Navnav'));
const Footer = lazy(() => import('./components/footer/Footer'));
const HeaderRoute = lazy(() => import('./components/routes/HeaderRoute'));

// user routes
const ForgotPassword = lazy(() => import('./pages/auth/ForgotPassword'));
const History = lazy(() => import('./pages/user/History'));
const Password = lazy(() => import('./pages/user/Password'));
const UserRoute = lazy(() => import('./components/routes/UserRoute'));
const Contact = lazy(() => import('./pages/user/Contact'));
const Checkout = lazy(() => import('./pages/Checkout'));
const Wishlist = lazy(() => import("./pages/user/Wishlist"));
const Unsubscribe = lazy(() => import("./pages/user/Unsubscribe"));

// admin routes
const AdminRoute = lazy(() => import('./components/routes/AdminRoute'));
const AdminDashboard = lazy(() => import('./pages/admin/AdminDashboard'));
const CategoryCreate = lazy(() => import('./pages/admin/category/CategoryCreate'));
const CategoryUpdate = lazy(() => import('./pages/admin/category/CategoryUpdate'));
const SubCreate = lazy(() => import('./pages/admin/sub/SubCreate'));
const SubUpdate = lazy(() => import('./pages/admin/sub/SubUpdate'));
const ProductCreate = lazy(() => import('./pages/admin/product/ProductCreate'));
const AllProducts = lazy(() => import('./pages/admin/product/AllProducts'));
const ProductUpdate = lazy(() => import('./pages/admin/product/ProductUpdate'));
const BlogPostCreate = lazy(() => import('./pages/admin/blogPosts/BlogPostCreate'));
const CreateCoupon = lazy(() => import('./pages/admin/coupon/CreateCoupon'));



AOS.init();

function App() {

  const dispatch = useDispatch();

  // check firebase auth state
  useEffect(() => {

    const unsubscribe = auth.onAuthStateChanged(async (user) => {

      if (user) {

        // get user token
        const idTokenResult = await user.getIdTokenResult();

        // populate user in redux store
        currentUser(idTokenResult.token)
          .then(
            (res) => {
              dispatch({
                type: 'LOGGED_IN_USER',

                payload: {
                  name: res.data.name,
                  email: res.data.email,
                  token: idTokenResult.token,
                  role: res.data.role,
                  _id: res.data._id,
                },

              });
            }

          )
          .catch((err) => {
            console.log(err.message);
          })
      }
    });

    // clean up

    return () => unsubscribe();

  }, [dispatch])



  return (

    <Suspense fallback={
      <div className={`Fallback d-flex justify-content-center align-items-center`}>
        <h2>Res<LoadingOutlined />nanceWebDesign.com</h2>
      </div>
    }>
      <NavNav />

      <ToastContainer />

      <Switch>
        {/* <Route exact path="/" component={UnderConstruction} /> */}
        <Route exact path="/" component={Home} />
        <Route exact path="/webdev" component={WebDev} />
        <Route exact path="/templatehome" component={TemplateHome} />
        <Route exact path="/blog" component={Blog} />
        <Route exact path="/blogpost/:slug" component={Blogpost} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/product/:slug" component={Product} />
        <UserRoute exact path="/user/history" component={History} />
        <UserRoute exact path="/user/Password" component={Password} />
        <UserRoute exact path="/checkout" component={Checkout} />
        <UserRoute exact path="/user/wishlist" component={Wishlist} />
        <UserRoute exact path="/payment" component={Payment} />
        <UserRoute exact path="/unsubscribe" component={Unsubscribe}/>
        <Route exact path="/register/complete" component={RegisterComplete} />
        <Route exact path="/forgot/password" component={ForgotPassword} />
        <AdminRoute exact path="/admin/dashboard" component={AdminDashboard} />
        <AdminRoute exact path="/admin/category" component={CategoryCreate} />
        <AdminRoute exact path="/admin/category/:slug" component={CategoryUpdate} />
        <AdminRoute exact path="/admin/sub" component={SubCreate} />
        <AdminRoute exact path="/admin/sub/:slug" component={SubUpdate} />
        <AdminRoute exact path="/admin/product" component={ProductCreate} />
        <AdminRoute exact path="/admin/product/:slug" component={ProductUpdate} />
        <AdminRoute exact path="/admin/products" component={AllProducts} />
        <AdminRoute exact path="/admin/coupon" component={CreateCoupon} />
        <AdminRoute exact path="/admin/blogPost" component={BlogPostCreate} />
      </Switch>



      <Footer />
    </Suspense>



  );
}

export default App;
