import { combineReducers } from 'redux';
import { userReducer } from './userReducer';
import { cartReducer } from './CartReducer';
import { couponReducer } from './couponReducer';

// combine reducers into root reducer
const rootReducer = combineReducers({
    user: userReducer,
    cart: cartReducer,
    coupon: couponReducer,
});

export default rootReducer;